import * as React from 'react'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Zamjenik = () => {
  return (
    <>
      <Seo title='Igor Ivančić, kandidat za zamjenika Gradonačelnika, nezavisni kandidat' />
      <Layout>
        <div className='my-8 md:grid md:grid-cols-2 md:gap-8 md:place-content-start	'>
          <StaticImage
            src='../images/igor-ivancic.jpg'
            alt='Igor Ivančić'
            layout='fullWidth'
            placeholder='blurred'
            quality='100'
            objectFit='contain'
            objectPosition='0 0'
            as='div'
            className='mb-4 wide'
          />
          <div className='bio'>
            <h2>IGOR IVANČIĆ</h2>
            <h3>kandidat za zamjenika Gradonačelnika, nezavisni kandidat</h3>
            <p>
              Rođen sam u lipnju 1973. godine, od rođenja do danas živim i radim
              u svom rodnom gradu.
            </p>
            <p>
              I osnovnu i srednju &scaron;kolu zavr&scaron;io sam u Crikvenici,
              a nakon srednje &scaron;kole upisao sam hotelijerski fakultet u
              Opatiji. Na drugoj godini studija morao sam odustati zbog
              poslovnih obaveza. Naime, kao 4. generacija obrtničke familije bio
              sam predodređen da nastavim dugogodi&scaron;nju tradiciju i nisam
              stizao spojiti studij i posao, tako da sam se 100% posvetio
              obiteljskom obrtu.
            </p>
            <p>
              1998. godine sam položio majstorski ispit za fotografa i od tada
              službeno preuzimam vođenje posla. Bila su to izazovna vremena jer
              je u Crikvenici bilo 5 foto studija, a dolaskom modernih
              tehnologija ulaganja su bila sve veća, a posla sve manje, ali
              upornost i ljubav prema poslu su se isplatili jer evo, jo&scaron;
              dan danas opstajem na trži&scaron;tu i ostao sam posljednji foto
              studio od Zadra do Rijeke.
            </p>
            <p>
              Naravno, biti obrtnik nije lako i potrebna su mnoga odricanja i
              velika žrtva, ali zahvaljujući podr&scaron;ci i strpljenju svoje
              supruge, s kojom sam u braku od 2003. godine i imam dvoje djece,
              sve je lak&scaron;e.
            </p>
            <p>Zadnjih godina aktivno sam uključen u rad udruženja obrtnika.</p>
            <p>
              Politikom se nikada nisam bavio, ali mislim da je sada
              do&scaron;lo vrijeme da svoje radne navike i upornost
              poku&scaron;am primijeniti u vođenju grada.
            </p>
            <p>
              Dužnost zamjenika gradonačelnika obavljat ću volonterski i bit ću
              podrška kolegama obrtnicima i posvećen razvoju malog
              poduzetništva.
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Zamjenik
